// @flow
import { phone } from 'phone';
import Swal from 'sweetalert2';
import api from '../helpers/api';
import { toast } from 'react-toastify';
//via Stack Overflow: https://stackoverflow.com/a/56253298
function flattenObj(obj, parent, res = {}) {
  for (let key in obj) {
    let propName = parent ? parent + '_' + key : key;
    if (typeof obj[key] == 'object') {
      flattenObj(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

function formatData(type, data) {
  switch (type) {
    case 'date':
      if (!data) data = new Date();
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }).format(new Date(data));
    case 'formal-date':
      if (!data) data = new Date();
      else data = new Date(data);
      return data.toISOString().substring(0, 10);
    case 'time':
      if (!data) data = new Date();
      else data = new Date(data);
      const datePart = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }).format(data);
      const timePart = data.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
      return `${datePart} ${timePart}`;
    case 'phone':
      let newStr = '(' + data.substring(0, 3) + ') ' + data.substring(3, 6) + '-' + data.substring(6, 10);
      return newStr;
    case 'currency':
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data || 0);
    case 'percent':
      return new Intl.NumberFormat('en-US', { style: 'percent' }).format(data / 100 || 0);
    default:
      return data;
  }
}

//courtesy of: http://blog.nicohaemhouts.com/2015/08/03/accessing-nested-javascript-objects-with-string-key/
function getNestedValue(theObject, path, separator) {
  try {
    separator = separator ?? '.';
    return path
      .split(separator)
      .reduce(function (obj, property) {
        return obj[property];
      }, theObject);

  } catch (err) {
    return undefined;
  }
}

const sortByName = (a, b) => {
  const valueA = a.fullName || a.customer?.fullName || '';
  const valueB = b.fullName || b.customer?.fullName || '';
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

const formatPhoneNumber = (phoneNumber) => {
  var cleaned = (phoneNumber?.replace(/^1/, '').replace(/^\+1/, ''));
  var match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return cleaned;
};

/**
 * 
 * @param {string?} phoneNumber 
 * @returns a formatted phone number, or undefined/null  for invalid numbers
 */
const validatePhoneNumber = (phoneNumber) => {
  return phone(phoneNumber, { country: 'USA' }).phoneNumber || phone(phoneNumber, { country: 'CAN' }).phoneNumber;
};

const getHashOfString = (str) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str?.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};
const normalizeHash = (hash, min, max) => {
  return Math.floor((hash % (max - min)) + min);
};

const backgroundGenerator = (name) => {
  const hRange = [0, 360];
  const sRange = [40, 80];
  const lRange = [25, 60];
  let hash, hsl;
  const generateHSL = (name) => {
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, hRange[0], hRange[1]);
    const s = normalizeHash(hash, sRange[0], sRange[1]);
    const l = normalizeHash(hash, lRange[0], lRange[1]);
    return [h, s, l];
  };

  const HSLtoString = (hsl) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
  };

  hash = generateHSL(name);
  hsl = HSLtoString(hash);
  return hsl;
};


function horizontalWheel(container) {
  /** Max `scrollLeft` value */
  let scrollWidth;

  /** Desired scroll distance per animation frame */
  let getScrollStep = () => scrollWidth / 50 /* ADJUST TO YOUR WISH */;

  /** Target value for `scrollLeft` */
  let targetLeft;

  function scrollLeft() {
    let beforeLeft = container.scrollLeft;
    let wantDx = getScrollStep();
    let diff = targetLeft - container.scrollLeft;
    let dX = wantDx >= Math.abs(diff) ? diff : Math.sign(diff) * wantDx;

    // Performing horizontal scroll
    container?.scrollBy(dX, 0);

    // Break if smaller `diff` instead of `wantDx` was used
    if (dX === diff)
      return;

    // Break if can't scroll anymore or target reached
    if (beforeLeft === container.scrollLeft || container.scrollLeft === targetLeft)
      return;

    requestAnimationFrame(scrollLeft);
  }

  container?.addEventListener('wheel', e => {
    e.preventDefault();

    scrollWidth = container.scrollWidth - container.clientWidth;
    targetLeft = Math.min(scrollWidth, Math.max(0, container.scrollLeft + e.deltaY));

    requestAnimationFrame(scrollLeft);
  });
}

/**
 * @param {string} value 
 * @returns string
 * @description This function takes a string and returns a formatted string based on the value
 * @description The default return is to replace underscores with spaces and capitalize first letter of each word
 * @description You can add more cases to the switch statement to format specific values differently
 */
const valueFormatter = (value) => {
  if (!value || typeof value !== 'string') return value;
  switch (value) {

    //payment methods
    case 'cc':
      return 'Credit Card';
    case 'wire':
      return 'Wire Transfer';
    case 'ach':
      return 'ACH';
    case 'zelle':
      return 'Zelle/Quickpay';

    // trip payment statuses

    // trip dispatch statuses

    // invoice statuses
    case 'over_paid':
      return 'Overpaid';
    case 'partial_paid':
      return 'Partially Paid';

    // package types

    // // trip statuses


    // Customer settings
    case 'allow_without':
      return 'Allow without Signature';
    case 'return_to_hub':
      return 'Return to Errands Hub';

    default:
      // replace underscores with spaces, add a space before each capital letter, and capitalize first letter of each word
      return (value ?? '')
        .replace(/_id$/, '') // remove _id from the end of strings
        .replace(/_/g, ' ') // replace underscores with spaces
        .replace('driver', 'pro') // replace driver with pro everywhere
        .replace(/([A-Z])/g, ' $1')
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
        });
  }
};
const duplicateTrip = async (e, trip, setTrips) => {
  e.preventDefault();
  Swal.fire({
    title: 'Duplicate Order?',
    // text: 'Are you sure you want to duplicate this trip?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Duplicate',
    cancelButtonText: 'Cancel',
    dangerMode: true,
    closeOnClickOutside: false,
  }).then(async (result) => {
    if (result.isConfirmed) {
      const allowedFields = [
        'pickupAddress',
        'dropoffAddress',
        'note',
        'customer_id',
        'price',
        'status',
        'packageType',
        'numberOfPackages',
        'pickupName',
        'dropoffName',
        'pickupPhone',
        'dropoffPhone',
        'tags'
      ];
      const copy = {};
      for (let field of allowedFields) {
        copy[field] = trip[field];
      }

      const id = toast.loading('Duplicating order ' + trip.orderNumber);

      api('trips', 'POST', copy)
        .then((res) => {
          setTrips(t => [res, ...t]);
          toast.update(id, {
            render: `Order ${trip.orderNumber} duplicated. New order order number: ${res.orderNumber}`,
            type: 'success',
            isLoading: false,
            autoClose: 500
          });
        })
        .catch((err) => {
          toast.update(id, { render: 'Failed to duplicate order ' + trip.orderNumber, type: 'error', isLoading: false, autoClose: 500 });
        });

    }
  });
};

const calculateTripsInRange = (tripScheduleTime) => {
  const currentTime = new Date().toISOString();
  const futureTime = new Date();
  futureTime.setHours(futureTime.getHours() + 6);
  const formattedFutureTime = futureTime.toISOString();
  return (tripScheduleTime > currentTime);// && (tripScheduleTime <= formattedFutureTime);
};
export { formatData, flattenObj, getNestedValue, sortByName, formatPhoneNumber, validatePhoneNumber, getHashOfString, normalizeHash, backgroundGenerator, horizontalWheel, valueFormatter, duplicateTrip, calculateTripsInRange };