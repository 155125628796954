import React, { createContext, useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import api from '../helpers/api';
import { toast } from 'react-toastify';
import api2 from '../helpers/api2';

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(false);
  const [error, setError] = useState(null);
  const [soundOn, setSoundOn] = useState(true);
  const socket = useRef();
  let history = useHistory();
  let location = useLocation();
  const [profileImage, setProfileImage] = useState();

  const logoutEvent = (e) => {
    e.preventDefault();
    logoutUser();
  };

  useEffect(() => {
    setProfileImage(user?.customer?.orderform?.logoLink ?? null);
  }, [user]);

  useEffect(() => {
    window.addEventListener('onbeforeunload', logoutEvent);
    return () => window.removeEventListener('onbeforeunload', logoutEvent);
  });

  useEffect(() => {
    api2
      .get('/auth/me')
      .then((res) => {
        if (res.status === 200) {
          socket.current = window.io(`${process.env.REACT_APP_API_URL}/${res.data.role.toLowerCase()}`, { withCredentials: true });
          socket.current.on('logout', logoutUser);

          setUser(res.data);
        }
      })
      .catch((err) => setUser(null));
  }, []);

  useEffect(() => {
    if (error)
      setTimeout(() => setError(false), 4000);
  }, [error]);

  // register user
  const registerUser = ({ firstName, lastName, companyName, phoneNumber, email, password }) => {
    setError(null);
    setUserLoading(true);
    api('auth/register', 'POST', { firstName, lastName, companyName, phoneNumber, email, password })
      .then((res) => {
        console.log(res);
        setError(null);
        setUserLoading(false);
        Swal.fire({
          title: 'you have been registered',
          text: 'Redirecting to login...',
          icon: 'success',
          timer: 4000,
          showConfirmButton: false,
        })
          .then(() => {
            console.log('redirecting to login page');
            history.push('/account/login');
          });
      })
      .catch(e => {
        setUserLoading(false);
        console.log(e);
        if (typeof e === 'object' && e.status === 400) {
          setError('User already exists. Redirecting to login');
          setTimeout(() => {
            history.push('/account/login');
          }, 4000);
        }
        else {
          setError(e);
        }
      });
  };

  // login user
  const loginUser = (email, password) => {
    setError(null);
    setUserLoading(true);
    return api('auth/login', 'POST', { email, password })
      .then(res => {
        if (res.id) {
          socket.current = window.io(`${process.env.REACT_APP_API_URL}/${res.role.toLowerCase()}`, { withCredentials: true });
          socket.current.on('logout', logoutUser);

          setUser(res);
          setError(null);

          if (location.state && location.state.from) history.push(location.state.from);
          else history.push('/');
          return true;
        }
      })
      .catch(e => {
        console.log(e);
        setError(e);
        const div = document.getElementById('verify-div');
        const btn = document.getElementById('verify-btn');
        btn.onclick = () => { api('auth/reqVerification', 'POST', { email: email }).then(() => toast.success('Verification email sent')); };
        div.style.display = 'block';
      })
      .finally(() => setUserLoading(false));
  };

  // forgot password
  const passwordReset = async (email, role) => {
    setUserLoading(true);
    return api('auth/reqPassword', 'POST', { email, role })
      .then((res) => {
        console.log(res);
        //setError(null);
        setUserLoading(false);
      })
      .catch(e => {
        console.log(e);
        //setError();
        setUserLoading(false);
      });
  };

  // is logged in
  const isLoggedIn = () => {
    return user ? true : false;
  };

  // logout user
  const logoutUser = () => {
    socket.current.off('logout', logoutUser);
    api2.post('/auth/logout');
    setUser(null);
  };

  const defaultRoute = () => {
    if (user.role === 'Dispatcher')
      return <Redirect to='/dispatch' />;
    else if (user.role === 'Customer')
      return <Redirect to='/openorders' />;
    else if (user.role === 'Driver')
      return <Redirect to='/pro' />;
    else
      return <Redirect to='/404' />;
  };

  return (
    <UserContext.Provider
      value={{
        user,
        userLoading,
        error,
        soundOn,
        socket,
        loginUser,
        logoutUser,
        isLoggedIn,
        setSoundOn,
        registerUser,
        passwordReset,
        defaultRoute,
        profileImage,
        setProfileImage
      }}>
      {props.children}
    </UserContext.Provider>
  );
};
