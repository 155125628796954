/**
 * @param {string} url the API endpoint
 * @param {'GET' | 'POST' | 'PUT' | 'DELETE'} method the HTTP method
 * @param {object?} body the payload
 * @returns {Promise<object>} the response data or error message
 */
export default async function api(url, method = 'GET', body = undefined) {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_API_URL + '/' + url, {
      headers: body
        ? {
          'Content-Type': 'application/json',
        }
        : undefined,
      credentials: 'include',
      method,
      body: body ? JSON.stringify(body) : undefined,
    })
      .then(res => res.text().then(text => { return { status: res.status, ok: res.ok, json: text ? JSON.parse(text) : {} }; }))
      .then(res => {
        if (!res.ok) {
          if (res.json && !res.json.error) return reject(res);
          return reject(res.json.error.message ?? 'Server error');
        }
        return resolve(res.json);
      });
    // return res.json();
  });
};