import React from 'react';

class SoundService extends React.Component {
	audio = new Audio('/bell.mp3');
	_hasListener = false;

	silentPlay = () => {
		this.audio.volume = 0;
		this.play();
		setTimeout(() => {
			this.audio.pause();
			this.audio.currentTime = 0;
			this.audio.volume = 1;
		}, 1000);
	};

	play = () => {
		this.audio
			.play()
			.catch((err) => {
				console.log(err);
			});
	};
}
export let soundService = new SoundService();


