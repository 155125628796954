import axios from "axios";
import Qs from "qs";


/**
 * Axios instance with configuration. GET params can be passed as an object with the property name params within the request object.
 */
const api2 = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL + '/',
  paramsSerializer: (params) => {
    return Qs.stringify(params);
  },
});
export default api2;


export const parseResError = (err) => {
  const { details, message, error } = err?.response?.data;
  let errorMessage = message || error?.message || 'Something went wrong';
  if (details && details.payloadErrors) {
    const payloadErrors = details.payloadErrors;
    errorMessage = Object.keys(payloadErrors).map(field => {
      const errors = payloadErrors[field];
      return errors.map(error => `${field} is ${error.toLowerCase()}`).join(', ');
    }).join(', ');
  }
  return errorMessage;
};
