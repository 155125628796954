// @flow
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { UserProvider, UserContext } from './contexts/UserContext';
import { FaEnvelope, FaShoppingBag, FaBox, FaGift, FaSuitcaseRolling, FaShuttleVan, FaTruck, FaCaravan, FaBusAlt, FaBus } from 'react-icons/fa';
import { RiMailStarFill } from 'react-icons/ri';
import { TbHanger, TbLayersUnion } from 'react-icons/tb';
import { GiFlowerPot, GiShatteredGlass, GiPaperTray } from 'react-icons/gi';
import { MdDirectionsBike, MdDirectionsCar } from 'react-icons/md';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/Routes';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/Saas.scss';
import { packageTypes, tags, vehicleTypes, languages } from './constants/sources';
import { HelmetProvider } from 'react-helmet-async';

const getIcon = (type) => {
  switch (type) {
    case "bag":
      return <FaShoppingBag style={{ verticalAlign: 'text-top' }} />;
    case "envelope":
      return < FaEnvelope style={{ verticalAlign: 'text-top' }} />;
    case "box":
      return < FaBox style={{ verticalAlign: 'text-top' }} />;
    case "invitation":
      return < RiMailStarFill style={{ verticalAlign: 'text-top' }} />;
    case "luggage":
      return < FaSuitcaseRolling style={{ verticalAlign: 'text-top' }} />;
    case "gift_arrangement":
      return <FaGift style={{ verticalAlign: 'text-top' }} />;
    case "flower":
      return <GiFlowerPot style={{ verticalAlign: 'text-top' }} />;
    case "garment_bag":
      return <TbHanger style={{ verticalAlign: 'text-top' }} />;
    case "delicate":
      return <GiShatteredGlass style={{ verticalAlign: 'text-top' }} />;
    case "open_wrap":
      return <GiPaperTray style={{ verticalAlign: 'text-top' }} />;
    case "bike":
      return <MdDirectionsBike style={{ verticalAlign: 'text-top' }} />;
    case "car":
      return <MdDirectionsCar style={{ verticalAlign: 'text-top' }} />;
    case "van":
      return <FaShuttleVan style={{ verticalAlign: 'text-top' }} />;
    case "truck":
      return <FaTruck style={{ verticalAlign: 'text-top' }} />;
    case "minivan":
      return <FaBus style={{ verticalAlign: 'text-top' }} />;
    case "cargo":
      return <FaCaravan style={{ verticalAlign: 'text-top' }} />;
    case "sprinter":
      return <FaBusAlt style={{ verticalAlign: 'text-top' }} />;
    default:
      return <TbLayersUnion style={{ verticalAlign: 'text-top' }} />;
  }
};

const getLabel = (type) => {
  const words = type.split('_');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(' ');
};

const App = (): React$Element<any> => {
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/packageTypes')
      .then(res => res.json())
      .then(res => res.forEach(o => packageTypes.push({ name: "packageType", value: o, label: getLabel(o), icon: getIcon(o) })))
      .catch(e => console.log(e));

    fetch(process.env.REACT_APP_API_URL + '/tags')
      .then(res => res.json())
      .then(res => res.forEach(o => tags.push(o)))
      .catch(e => console.log(e));

    fetch(process.env.REACT_APP_API_URL + '/vehicleTypes')
      .then(res => res.json())
      .then(res => res.forEach(o => vehicleTypes.push({ name: "vehicleTypes", value: o, label: getLabel(o), icon: getIcon(o) })))
      .catch(e => console.log(e));

    // fetch(process.env.REACT_APP_API_URL + '/languages')
    //     .then(res => res.json())
    //     .then(res => res.forEach(o => languages.push({ name: "languages", value: o, label: getLabel(o), icon: getIcon(o) })))
    //     .catch(e => console.log(e));

  }, []);

  return <Routes></Routes>;
};

ReactDOM.render(
  <BrowserRouter>
    <UserProvider>
      <HelmetProvider>
        <App />
        <ToastContainer
          position='bottom-right'
          autoClose={500}
          theme='light'
          // limit={3} // causes newer toasts to show after the other toasts finish loading - too late, not inteded behavor
          newestOnTop
          closeOnClick={true}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          transition={Slide}
        />
      </HelmetProvider>
    </UserProvider>
  </BrowserRouter>,
  document.getElementById('root')
);