// @flow
import React, { Suspense, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
//const Topbar = React.lazy(() => import('../TopbarNew'));
const Navbar = React.lazy(() => import('./Navbar'));
const Footer = React.lazy(() => import('../Footer'));

const loading = () => <div className="text-center"></div>;

type HorizontalLayoutProps = {
  children?: any,
};

type HorizontalLayoutState = {
  isMenuOpened: boolean,
};

const HorizontalLayout = ({ children }: HorizontalLayoutProps, state: HorizontalLayoutState): React$Element<any> => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.remove('sidebar-enable');
      } else {
        document.body.classList.add('sidebar-enable');
      }
    }
  };
  useEffect(() => {
    const contentPage = document.querySelector('.content-page');
    if (window.location.pathname === '/dispatch-tablet') {
      contentPage.style.padding = '5px 12px 65px';
    }
  }, []);
  return (
    <>
      <div className="wrapper">
        <div className="content-page pb-2">
          <div className="content">
            <Suspense fallback={loading()}></Suspense>

            <Suspense fallback={loading()}>
              <Navbar isMenuOpened={isMenuOpened} />
            </Suspense>

            <Container fluid>
              <Suspense fallback={loading()}>{children}</Suspense>
            </Container>
          </div>

          <Suspense fallback={loading()}>
            {/* <Footer /> */}
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default HorizontalLayout;
