import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, module = null, options, ...rest }) => {
  const { isLoggedIn, user } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn()) {
          //not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
        }

        //check if route is restricted by role
        if (!roles.includes(user.role)) {
          //role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/404', state: { from: props.location } }} />;
        }
        return <Component {...props} module={module} options={options} />;
      }}
    />
  );
};

export default PrivateRoute;
