import { soundService } from '../helpers/soundService';
import logo from '../assets/images/logo.png';

export function notify(tripId, soundOn) {
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");
  }
  else if (Notification.permission === "granted") {
    notification(soundOn, tripId);
  }
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        notification(soundOn, tripId);
      }
    });
  }
}

function notification(soundOn, tripId) {
  var n = new Notification("New trip created", { icon: logo, badge: logo, tag: tripId });
  if (soundOn) soundService.play();
}
